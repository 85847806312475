import React, {useState, useMemo, useContext, useRef} from 'react';
import PropTypes from 'prop-types';
import getCarModels from "./services/getCarModels";
import getCarVersions from "./services/getCarVersions";
import AutomotorContext from "./AutomotorContext";
import ClienteDirectoContext from "../../ClienteDirectoContext";

const AutomotorContextProvider = ({children, initialData}) => {
    const marcas = initialData || [];
    const {idRowPaginasPolkista} = useContext(ClienteDirectoContext)
    const anios = Array.from({length: 31}, (_, i) => ({value: (2025 - i).toString(), label: (2025 - i).toString()}));
    const [modelos, setModelos] = useState([]);
    const [versiones, setVersiones] = useState([]);
    const [isLoadingModels, setIsLoadingModels] = useState(false);
    const [isLoadingVersions, setIsLoadingVersions] = useState(false);
    const [modelsError, setModelsError] = useState(null);
    const [versionsError, setVersionsError] = useState(null);
    const vehicleDetails = useRef({
        detalle_marca: "",
        detalle_modelo: "",
        detalle_version: ""
    });

    const updateModels = (marca, anio) => {
        setModelos([]);
        setVersiones([]);
        setIsLoadingModels(true);
        setModelsError(null);
        getCarModels(idRowPaginasPolkista, marca, anio)
            .then(response => {
                setModelos(response.data);
            })
            .catch(error => {
                console.error('Error al actualizar modelos:', error);
                setModelsError(error);
            })
            .finally(() => {
                setIsLoadingModels(false);
            });
    };

    const updateVersions = (marca, anio, modelo) => {
        setVersiones([]);
        setIsLoadingVersions(true);
        setVersionsError(null);
        getCarVersions(idRowPaginasPolkista, marca, anio, modelo)
            .then(response => {
                setVersiones(response.data);
            })
            .catch(error => {
                console.error('Error al actualizar versiones:', error);
                setVersionsError(error);
            })
            .finally(() => {
                setIsLoadingVersions(false);
            });
    };

    const context = useMemo(() => ({
        marcas,
        anios,
        modelos,
        versiones,
        updateModels,
        updateVersions,
        isLoadingModels,
        isLoadingVersions,
        modelsError,
        versionsError,
        vehicleDetails
    }), [
        marcas,
        anios,
        modelos,
        versiones,
        isLoadingModels,
        isLoadingVersions,
        modelsError,
        versionsError,
        vehicleDetails
    ]);

    return (
        <AutomotorContext.Provider value={context}>
            {children}
        </AutomotorContext.Provider>
    );
};

AutomotorContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default AutomotorContextProvider;
